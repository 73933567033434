export const groupItems = (items, cart) => {
  return items.reduce((acc, item) => {
    const bundlePromotion = cart?.getBundlePromotionsForItem(item);

    // Use the promotion ID or bundle ID to uniquely identify each bundle group
    const bundleId = bundlePromotion?.id || item.getBundleActive() || "noBundle";

    acc[bundleId] = acc[bundleId] || [];
    acc[bundleId].push(item);

    return acc;
  }, {});
};

export const getPromotionStatusMessage = bundleItems => {
  return bundleItems.some(item => item.getBundleActive() || item.getBogoActive())
    ? "Applied!"
    : "Available!";
};
